<template>
  <div class="navbar">
    <h2>Gonzalo Fernández</h2>
    <a :href="linksData.github">
      <img src="./assets/github-logo.png">
    </a>
    <a :href="linksData.linkedin">
      <img src="./assets/linkedin-logo.png">
    </a>
    <a :href="linksData.mail">
      <img src="./assets/mail.png">
    </a>
    <a class="resume" :href="linksData.resume">View full Resume</a>
  </div>   
  <div class="content">
    <div class="events">
      <div v-for="(event, index) in eventsData" :key="index">
        <ProfessionalEvent v-if="event.type === 'professional'" :data="event"/>
      </div>
    </div>
  </div>
</template>

<script>

import eventsData from './data/events.json';
import linksData from './data/links.json';
import ProfessionalEvent from './components/ProfessionalEvent.vue'

export default {
  name: 'App',
  components: {
    ProfessionalEvent,
  },
  mounted() {
    this.eventsData = eventsData;
    this.linksData = linksData;
  },
  data() {
    return {
      linksData() {
        return this.linksData;
      }
    }
  }
}
</script>

<style>
body {
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a {
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c3e50;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  padding: 10px;
}

.navbar > * {
  margin-left: 20px;
}

.navbar > h2 {
  margin: 0;
  color: white;
}

.navbar > a {
  display: flex;
}

.navbar > a > img {
  height: 40px;
}

.resume {
  border: 2px solid white;
  color: white;
  padding: 7px;
  border-radius: 4px;
  font-size: 0.8rem;
  text-align: center;
}

.content {
  margin: 100px 25% 0 25%;
}

.events {
  text-align: center;
}

.events > div {
  margin: 75px 0;
}

.events > div:first-child {
  margin-top: 0;
}
</style>
