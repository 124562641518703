<template>
  <div class="event">
    <h2>{{ data.role }} @ {{ data.company }}</h2>
    <h3>{{ data.dates }}</h3>
    <h3>{{ data.description }}</h3>
    <div class="tags">
        <Tag v-for="(tag, index) in data.tags" :key="index" :title=tag />
    </div>
  </div>
</template>

<script>
import Tag from './Tag.vue'

export default {
  name: 'ProfessionalEvent',
  components: {
      Tag
  },
  props: {
    data: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tags {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  max-height: 20px;
}
</style>
