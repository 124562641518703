<template>
  <div class="tag" :class="getType(title)">
    <a :href="getUrl(title)">{{ title }}</a>
  </div>
</template>

<script>

import tagsMetadata from '../data/tags-metadata.json';

export default {
  name: 'Tag',
  props: {
    title: String,
    url: String,
  },
  mounted() {
      this.tagsMetadata=tagsMetadata
  },
  methods: {
    getType: function (title)  {
        return Object.keys(tagsMetadata)
            .filter(key => key.toLowerCase() === title.toLowerCase())
            .map(key => tagsMetadata[key])
            .map(tag => tag.type)
    },
    getUrl: function (title) {
        return Object.keys(tagsMetadata)
            .filter(key => key.toLowerCase() === title.toLowerCase())
            .map(key => tagsMetadata[key])
            .map(tag => tag.url)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cicd {
    background-color: #F7CB15;
}
.cicd a {
    color: black;
}
.stack {
    background-color: #DE3C4B;
}
.stack a {
    color: white;
}
.concept {
    background-color: #4eadbc;
}
.concept a {
    color: white;
}
a {
  text-decoration: none;
}
</style>
